import './Footer.css';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <div className="footer">
            <p>© {year} Gable Photo</p>
        </div>
    );
}

export default Footer;
