import './Navigation.css';
import { BrowserView, MobileView } from 'react-device-detect';
import { FaInstagram, FaRegEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import Footer from './Footer';

function Navigation(props) {
    const [isOpen, setOpen] = useState(false);
    const iconStyle = {
        color: 'white',
        fontSize: '2em',
        verticalAlign: 'bottom',
    };
    const style = {
        transform: isOpen ? 'translateY(0%)' : 'translateY(-150%)',
        transition: '0.15s',
    };
    const headerStyle = {
        height: isOpen ? '230px' : '62px',
        transition: '0.15s',
    };

    return (
        <div className="App">
            <BrowserView>
                <div className="row">
                    <div className="browserNavigation">
                        <p className="browserNavigationHeader">
                            <a href="/">Gable Photo</a>
                        </p>
                        <p className="browserNavigationText">
                            <a href="/about">About</a>
                        </p>
                        <div className="icons">
                            <a href="https://aemail.com/JZPJ">
                                <FaRegEnvelope style={iconStyle} />
                            </a>
                            <a href="https://www.instagram.com/gable.photo/">
                                <FaInstagram style={iconStyle} />
                            </a>
                        </div>
                    </div>
                    <div className="browserContent">
                        {props.children}
                        <Footer />
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className="column">
                    <div
                        className="mobileNavigation column"
                        style={headerStyle}
                    >
                        <div className="mobileNavigationHeader">
                            <a href="/">Gable Photo</a>
                            {isOpen ? (
                                <FaTimes
                                    size={28}
                                    style={iconStyle}
                                    onClick={() => setOpen(!isOpen)}
                                />
                            ) : (
                                <FaBars
                                    size={28}
                                    style={iconStyle}
                                    onClick={() => setOpen(!isOpen)}
                                />
                            )}
                        </div>
                        <div style={style}>
                            <p className="mobileNavigationText">
                                <a href="/about">About</a>
                            </p>
                            <div className="icons">
                                <a href="https://aemail.com/JZPJ">
                                    <FaRegEnvelope style={iconStyle} />
                                </a>
                                <a href="https://www.instagram.com/gable.photo/">
                                    <FaInstagram style={iconStyle} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mobileContent">
                        {props.children}
                        <Footer />
                    </div>
                </div>
            </MobileView>
        </div>
    );
}

export default Navigation;
