import Gallery from './Gallery';
import Navigation from './Navigation';

function App() {
    return (
        <Navigation>
            <Gallery />
        </Navigation>
    );
}

export default App;
