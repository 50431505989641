import { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import photos from './Photos';
import { isMobileOnly, isTablet } from 'react-device-detect';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import Captions from 'yet-another-react-lightbox/plugins/captions';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/captions.css';

function Gallery() {
    const [index, setIndex] = useState(-1);
    return (
        <div style={{ padding: '4px' }}>
            <PhotoAlbum
                photos={photos}
                layout={'columns'}
                spacing={4}
                columns={() => {
                    if (isMobileOnly) return 1;
                    if (isTablet) return 2;
                    return 3;
                }}
                componentsProps={() => ({
                    imageProps: { loading: 'eager' },
                })}
                onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                animation={{ fade: 150, swipe: 150 }}
                controller={{
                    focus: true,
                    aria: false,
                    touchAction: 'pan-y',
                    closeOnBackdropClick: true,
                }}
                close={() => setIndex(-1)}
                plugins={[Captions, Zoom]}
            />
        </div>
    );
}

export default Gallery;
