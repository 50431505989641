import Navigation from './Navigation';
import tux from './photos/tux.avif';
import './About.css';
import { isMobileOnly } from 'react-device-detect';

function About() {
    const className = isMobileOnly ? 'mobileContainer' : 'browserContainer';
    return (
        <Navigation>
            <div className={className}>
                <h1>About Gable Photo</h1>
                <img alt="Andrew Gable" src={tux} />
                <p>
                    Hello my name is Andrew and welcome to my photography
                    website! 👋
                </p>
                <p>
                    I am located in Boise, Idaho 📍 and I love to take photos of
                    sports, animals, and beautiful places.
                </p>
                <p>
                    If I gave you a business card at a sporting event{' '}
                    <a href="https://aemail.com/JZPJ">email</a> me your race
                    number and I will be happy to send photos I have of you to
                    share!
                </p>
            </div>
        </Navigation>
    );
}

export default About;
