import bogusBasinBikeRaceOne_3337x5006 from './photos/6L4A0467.avif';
import bogusBasinBikeRaceTwo_2940x4410 from './photos/6L4A4031.avif';
import bogusBasinBikeRaceThree_1000x1500 from './photos/6L4A4500.avif';
import bogusBasinBikeRaceFour_2659x3989 from './photos/6L4A7032.avif';

import bogusBasinSkiRaceOne_1800x1200 from './photos/6L4A3476.avif';
import bogusBasinSkiRaceTwo_3025x4537 from './photos/6L4A9083.avif';

import bogusBasinSkiOne_1000x1500 from './photos/6L4A5422.avif';

import bogusBasinTrailRunOne_4978x7463 from './photos/6S9A0792.avif';

import serengetiCheetahOne_5464x8192 from './photos/6S9A0559.avif';
import serengetiCheetahTwo_7026x4686 from './photos/6S9A0750-2.avif';
import serengetiCheetahThree_8192x5464 from './photos/6S9A1217.avif';
import serengetiLionThree_5311x7963 from './photos/6S9A1657.avif';
import serengetiLionFour_6532x4357 from './photos/6S9A2327.avif';
import serengetiWildebeestOne_3644x5464 from './photos/6S9A5344.avif';

import boiseMillerOne_1799x1200 from './photos/6S9A8950.avif';

import kenyaCheetahOne_1333x2000 from './photos/DSC_0389.avif';
import kenyaLeopardOne_1333x2000 from './photos/DSC_2739.avif';
import kenyaElephantOne_1333x2000 from './photos/DSC_8975.avif';
import kenyaLionOne_2000x1333 from './photos/DSC_9631.avif';

import yosemiteSunriseOne_2000x1333 from './photos/DSC_1737.avif';

const Photos = [
    { src: serengetiCheetahOne_5464x8192, width: 5464, height: 8192 },
    { src: bogusBasinBikeRaceThree_1000x1500, width: 1000, height: 1500 },
    { src: bogusBasinSkiRaceOne_1800x1200, width: 1800, height: 1200 },
    { src: bogusBasinBikeRaceTwo_2940x4410, width: 2940, height: 4410 },
    { src: boiseMillerOne_1799x1200, width: 1799, height: 1200 },
    { src: bogusBasinBikeRaceFour_2659x3989, width: 2659, height: 3698 },
    { src: bogusBasinSkiRaceTwo_3025x4537, width: 3025, height: 4537 },
    { src: bogusBasinSkiOne_1000x1500, width: 1000, height: 1500 },
    { src: serengetiCheetahTwo_7026x4686, width: 7026, height: 4686 },
    { src: bogusBasinTrailRunOne_4978x7463, width: 4978, height: 7463 },
    { src: serengetiCheetahThree_8192x5464, width: 8192, height: 5464 },
    { src: serengetiLionFour_6532x4357, width: 6532, height: 4357 },
    { src: serengetiWildebeestOne_3644x5464, width: 3644, height: 5464 },
    { src: kenyaCheetahOne_1333x2000, width: 1333, height: 2000 },
    { src: serengetiLionThree_5311x7963, width: 5311, height: 7963 },
    { src: kenyaLeopardOne_1333x2000, width: 1333, height: 2000 },
    { src: kenyaElephantOne_1333x2000, width: 1333, height: 2000 },
    { src: bogusBasinBikeRaceOne_3337x5006, width: 3337, height: 5006 },
    { src: yosemiteSunriseOne_2000x1333, width: 2000, height: 1333 },
    { src: kenyaLionOne_2000x1333, width: 2000, height: 1333 },
];

export default Photos;
